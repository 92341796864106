<template>
  <base-section id="gallery">
    <v-container>
      <v-fade-transition
        group
        hide-on-leave
        tag="div"
        class="row"
      >
        <v-col
          v-for="(model, i) in models"
          :key="model.title"
          class="gallery-card"
          cols="12"
          sm="6"
          md="4"
        >
          <project-card
            v-bind="model"
            :src=model.src
            height="300"
            width="100%"
          />
        </v-col>
      </v-fade-transition>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionGallery',

    components: {
      ProjectCard: () => import('@/components/ProjectCard'),
    },

    data: () => ({
      models: [
        {
          title: 'Activated Carbon (coconut shell)',
          src: require('@/assets/activated carbon.jpg'),
        },
        {
          title: 'Antioxidant',
          src: require('@/assets/Antioxidants.png'),
        },
        {
          title: 'Antitack Powder Free',
          src: require('@/assets/antitack powder free.jpg'),
        },
        {
          title: 'Antifoam',
          src: require('@/assets/antifoam.jpg'),
        },
        {
          title: 'Acrylic Polymer Binder',
          src: require('@/assets/acrylic polymer binder.jpg'),
        },
        {
          title: 'Biocides',
          src: require('@/assets/biocides.jpg'),
        },
        {
          title: 'Coagulant',
          src: require('@/assets/coagulant.jpg'),
        },
        {
          title: 'Dispersing Agents',
          src: require('@/assets/Dispersing agents.jpeg'),
        },
        {
          title: 'Fragrance/Flavour',
          src: require('@/assets/Fragrance_Flavours.jpg'),
        },
        {
          title: 'Formic Acid',
          src: require('@/assets/formic acid.jpg'),
        },
        {
          title: 'Nitrile Butadiene Latex',
          src: require('@/assets/nitrile butadiene latex.jpg'),
        },
        {
          title: 'PVC Resin Paste',
          src: require('@/assets/PVC resin paste.jpg'),
        },
        {
          title: 'Polyurethane Wet Donning',
          src: require('@/assets/polyurethane wet donning.jpg'),
        },
        {
          title: 'Polyurethane Polymer',
          src: require('@/assets/polyurethane polymer.jpg'),
        },
        {
          title: 'Titanium Dioxide',
          src: require('@/assets/titanium dioxide.jpeg'),
        },
        {
          title: 'Wetting Agents',
          src: require('@/assets/Wetting agents.jpg'),
        },
        {
          title: 'Wetting Donning Agents',
          src: require('@/assets/wetting donning agents.jpg'),
        },
      ],
    }),

    computed: {
      projects () {
        return [
          { title: 'Cierra Vega', subtitle: 'Wordpress' },
          { title: 'Pierre Cox', subtitle: 'Statistics' },
          { title: 'Alden Cantrell', subtitle: 'Wordpress' },
          { title: 'Kierra Gentry', subtitle: 'Branding' },
          { title: 'Thomas Crane', subtitle: 'Shopping' },
          { title: 'Miranda Shaffer', subtitle: 'Branding' },
          { title: 'Bradyn Kramer', subtitle: 'Photography' },
          { title: 'Bailey Wolfe', subtitle: 'Wordpress' },
          { title: 'Lorelei Holloway', subtitle: 'Ecommerce' },
          { title: 'Justine Elliott', subtitle: 'Ecommerce' },
        ].filter(project => {
          if (this.tab.toLowerCase() === 'all') return true

          return project.subtitle.toLowerCase() === this.tab.toLowerCase()
        })
      },
    },
  }
</script>

<style lang="sass">
  .gallery-card
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)
</style>
